import {
  point3d,
  point2d,
  LWPolylineFlags,
  HatchPolylineBoundary,
  vertex,
  HatchBoundaryPaths,
  pattern,
  HatchPredefinedPatterns,
  DxfWriter,
} from "@tarikjabiri/dxf";

const DECREMENT_WIDTH = 0.01;
// const WALL_HEIGHT = 5;

interface Room {
  corners: number[][];
  index: number;
  label: string; // Added label field
  label_coord?: number[];
}

interface Line {
  startPoint: number[];
  endPoint: number[];
}

export function plotLines(dxf: DxfWriter, lines: number[][]) {
  for (let i = 0; i < lines.length - 2; i = i + 2) {
    const startPoint = lines[i];
    const endPoint = lines[i + 1];
    dxf.addLine(
      point3d(startPoint[0], startPoint[1]),
      point3d(endPoint[0], endPoint[1]),
    );
  }
}

export function plotRoom(room: Room, dxf: DxfWriter) {
  const corners = room.corners.map((corner) => [...corner]);

  let xMin = Infinity,
    xMax = -Infinity,
    yMin = Infinity,
    yMax = -Infinity;

  corners.forEach((corner) => {
    xMin = Math.min(xMin, corner[0]);
    xMax = Math.max(xMax, corner[0]);
    yMin = Math.min(yMin, corner[1]);
    yMax = Math.max(yMax, corner[1]);
  });

  const xMid = (xMin + xMax) / 2;
  const yMid = (yMin + yMax) / 2;

  corners.forEach((corner) => {
    if (corner[0] < xMid) {
      corner[0] += DECREMENT_WIDTH;
    } else {
      corner[0] -= DECREMENT_WIDTH;
    }
    if (corner[1] < yMid) {
      corner[1] += DECREMENT_WIDTH;
    } else {
      corner[1] -= DECREMENT_WIDTH;
    }
  });

  const vertices = corners.map((corner) => ({
    point: point2d(corner[0], corner[1]),
  }));

  dxf.addLWPolyline(vertices, {
    flags: LWPolylineFlags.Closed,
  });

  addHatch(dxf, room);

  // Add room label
  const labelX = room.label_coord ? room.label_coord[0] : xMid;
  const labelY = room.label_coord ? room.label_coord[1] : yMid;
  const labelHeight = Math.min(xMax - xMin, yMax - yMin) / 10; // Adjust this factor as needed
  dxf.addText(point3d(labelX, labelY, 0), labelHeight, room.label);
}
const WALL_THICKNESS = 1; // Adjust this value to control wall thickness
const VERTICAL_WALL_THICKNESS_FACTOR = 1; // Adjust this to make vertical walls thinner

function addHatch(dxf: DxfWriter, room: Room) {
  const polyline = new HatchPolylineBoundary();
  room.corners.forEach((corner) => {
    polyline.add(vertex(corner[0], corner[1]));
  });

  const boundary = new HatchBoundaryPaths();
  boundary.addPolylineBoundary(polyline);

  const mypattern = pattern({
    name: HatchPredefinedPatterns.SOLID,
  });

  const hatch = dxf.addHatch(boundary, mypattern);
  hatch.colorNumber = room.index + 1;
}

export function plotWalls(dxf: DxfWriter, lines: number[][]) {
  for (let i = 0; i < lines.length - 2; i = i + 2) {
    const startPoint = lines[i];
    const endPoint = lines[i + 1];

    const isVertical = startPoint[0] === endPoint[0];
    const thickness = isVertical
      ? WALL_THICKNESS * VERTICAL_WALL_THICKNESS_FACTOR
      : WALL_THICKNESS;

    const polyline = new HatchPolylineBoundary();
    if (isVertical) {
      polyline.add(vertex(startPoint[0] - thickness / 2, startPoint[1]));
      polyline.add(vertex(endPoint[0] - thickness / 2, endPoint[1]));
      polyline.add(vertex(endPoint[0] + thickness / 2, endPoint[1]));
      polyline.add(vertex(startPoint[0] + thickness / 2, startPoint[1]));
    } else {
      polyline.add(vertex(startPoint[0], startPoint[1] - thickness / 2));
      polyline.add(vertex(endPoint[0], endPoint[1] - thickness / 2));
      polyline.add(vertex(endPoint[0], endPoint[1] + thickness / 2));
      polyline.add(vertex(startPoint[0], startPoint[1] + thickness / 2));
    }

    const boundary = new HatchBoundaryPaths();
    boundary.addPolylineBoundary(polyline);

    const mypattern = pattern({
      name: HatchPredefinedPatterns.SOLID,
    });

    const hatch = dxf.addHatch(boundary, mypattern);
    hatch.colorNumber = 9;
  }
}
