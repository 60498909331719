import { Box, Switch, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useDispatch } from "react-redux";
import { toggleUnit } from "../redux/features/appState";

const DimensionsToggle = () => {
  const dispatch = useDispatch();
  const unit = useSelector(
    (state: RootState) => state.outputEditorButtonState.unit,
  );
  const handleToggleDimensions = useCallback(() => {
    dispatch(toggleUnit());
  }, [dispatch]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ffffff",
        padding: "5px",
        borderRadius: "8px",
        border: "1px solid #E0E0E0",
      }}
    >
      <Typography
        sx={{
          color: unit === "feet" ? "#1C4C82" : "#333333",
          fontSize: "16px",
          fontWeight: 600,
          pr: 1,
        }}
      >
        Feet
      </Typography>
      <Switch
        checked={unit === "meters"}
        onChange={handleToggleDimensions}
        sx={{
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#1C4C82",
            "&:hover": {
              backgroundColor: "rgba(28, 76, 130, 0.08)",
            },
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#1C4C82",
          },
        }}
      />
      <Typography
        sx={{
          color: unit === "meters" ? "#1C4C82" : "#333333",
          fontSize: "16px",
          fontWeight: 600,
          pl: 1,
        }}
      >
        Meters
      </Typography>
    </Box>
  );
};

export default DimensionsToggle;
