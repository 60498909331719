import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  FloorPlanItem,
  NewFloorPlanItem,
  setIndex,
  setSelectedFloorPlan,
  deleteSelectedFloorPlan,
} from "../../../redux/features/graph";
import { Box, Typography } from "@mui/material";
import svgUrl from "../../../assets/inputGraphEditor/eye.svg";
import svgUrl2 from "../../../assets/inputGraphEditor/close.svg";
import { Wall } from "../../outputEditor/components/Floorplan";
import { showToast } from "../../../redux/features/toast/toast.slice";

interface componentProps {
  isDimensioned: boolean;
}

const NewApiSideBarBoard: React.FC<componentProps> = (props) => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const popUpRef = useRef<fabric.Canvas | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [gridIsOn, setGridIsOn] = useState<boolean>(true);
  const { floorPlans } = useSelector((state: RootState) => state.graph);
  const dispatch = useDispatch<AppDispatch>();
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [startIndex, setStartIndex] = useState(0);
  const [canWidth, setCanWidth] = useState(0);
  const [canHeight, setCanHeight] = useState(0);

  const prevRFP = () => {
    if (startIndex >= 3) setStartIndex(startIndex - 3);
  };
  const nextRFP = () => {
    if (startIndex + 3 < floorPlans.length) setStartIndex(startIndex + 3);
  };

  const handleClickFloorPlan = (i: number) => {
    console.log("new floorplansss set index", i);
    dispatch(setIndex(i));
    dispatch(setSelectedFloorPlan(floorPlans[i]));
  };

  const loadRFP = () => {
    resetDrawBoard();
    if (!floorPlans) {
      return;
    }
    let xmin = Infinity;
    let ymin = Infinity;
    let xmax = -Infinity;
    let ymax = -Infinity;
    for (let i = 0; i < 3; i++) {
      const j = startIndex + i;
      if (j >= floorPlans.length) return;
      const rooms = floorPlans[j];
      const roomObjs: any[] = [];

      if (!rooms) {
        return;
      }

      // First loop to calculate xmin, xmax, ymin, ymax
      rooms.forEach((room: NewFloorPlanItem) => {
        const walls = room.walls;

        if (!walls) {
          return;
        }

        walls.forEach((wall: Wall) => {
          // Store xmin, xmax, ymin, ymax
          xmin = Math.min(xmin, wall.x1, wall.x2);
          xmax = Math.max(xmax, wall.x1, wall.x2);
          ymin = Math.min(ymin, wall.y1, wall.y2);
          ymax = Math.max(ymax, wall.y1, wall.y2);
        });
      });

      // Second loop to create and set the text objects
      rooms.forEach((room: NewFloorPlanItem, index: number) => {
        const walls = room.walls;

        if (!walls) {
          return;
        }

        const lineGroup = new fabric.Group();
        const roomCenter: { x: number; y: number } = { x: 0, y: 0 };

        let x: number = 0;
        let y: number = 0;

        walls.forEach((wall: Wall) => {
          const line = new fabric.Line(
            [wall.x1 * 25, wall.y1 * 25, wall.x2 * 25, wall.y2 * 25],
            {
              fill: "#fff",
              selectable: false,
              stroke: "black",
              strokeWidth: props.isDimensioned ? (xmax - xmin) / 10 : 1,
            },
          );

          x += wall.x1 + wall.x2;
          y += wall.y1 + wall.y2;
          lineGroup.addWithUpdate(line);
        });

        roomCenter.x = room.label_coord[0];
        roomCenter.y = room.label_coord[1];

        const text = new fabric.Text(`${index + 1}`, {
          fontSize: props.isDimensioned ? (xmax - xmin) * 2 : 10, // Dynamic font size based on xmax - xmin
          originX: "center",
          originY: "center",
          fill: "fff",
          selectable: false,
        });

        text.set({
          left:
            roomCenter.x * 25 + (props.isDimensioned ? (xmax - xmin) * 2 : 5),
          top: roomCenter.y * 25,
        });

        lineGroup.addWithUpdate(text);
        roomObjs.push(lineGroup);
      });

      let roomGroup: any;
      if (i === 0) {
        roomGroup = new fabric.Group([], {
          originX: "center",
          originY: "bottom",
          selectable: false,
        });
      } else if (i === 1) {
        roomGroup = new fabric.Group([], {
          originX: "center",
          originY: "center",
          selectable: false,
        });
      } else {
        roomGroup = new fabric.Group([], {
          originX: "center",
          originY: "top",
          selectable: false,
        });
      }

      roomObjs.forEach((obj: any) => {
        roomGroup.addWithUpdate(obj);
      });

      const width50 = canWidth * 0.4;
      const height20 = canHeight * 0.27;
      const scaleFactorW = width50 / roomGroup.getScaledWidth();
      const scaleFactorH = height20 / roomGroup.getScaledHeight();

      roomGroup.scaleX = scaleFactorW;
      roomGroup.scaleY = scaleFactorH;

      if (i === 0) {
        roomGroup.set({
          left: canWidth / 2,
          top: canHeight / 3,
        });
      } else if (i === 1) {
        roomGroup.set({
          left: canWidth / 2,
          top: canHeight / 2,
        });
      } else {
        roomGroup.set({
          left: canWidth / 2,
          top: (canHeight * 2) / 3,
        });
      }

      roomGroup.setCoords();
      if (canvasRef.current !== null) canvasRef?.current?.add(roomGroup);

      // Calculate and add the dimension text below each roomGroup
      const width = xmax - xmin;
      const height = ymax - ymin;

      const dimensionText = new fabric.Text(`Area: ${height} x ${width}`, {
        fontSize: 12,
        originX: "center",
        originY: "top",
        fill: "black",
        selectable: false,
      });

      dimensionText.set({
        left: roomGroup.getCenterPoint().x,
        top: roomGroup.getCenterPoint().y + roomGroup.getScaledHeight() / 2 + 5,
      });

      if (canvasRef.current !== null && props.isDimensioned)
        canvasRef.current.add(dimensionText);

      const rect = new fabric.Rect({
        originX: "center",
        originY: "center",
        left: roomGroup.getCenterPoint().x,
        top: roomGroup.getCenterPoint().y,
        width: roomGroup.getScaledWidth() * 2,
        height: roomGroup.getScaledHeight() * 1.0,
        rx: 5,
        ry: 5,
        fill: "#F2F7FC",
        stroke: "#7794B4",
        strokeWidth: 1,
        selectable: false,
        evented: false,
        visible: false,
      });

      if (canvasRef.current !== null) canvasRef?.current?.add(rect);
      canvasRef.current?.sendToBack(rect!);

      canvasRef.current?.on("mouse:down", function (event) {
        if (event.pointer) {
          if (rect.containsPoint(event.pointer)) {
            console.log("clickeddd", j);
            rect.set({ visible: !rect.visible });
            if (rect.visible) {
              handleClickFloorPlan(startIndex + i);
            } else {
              dispatch(deleteSelectedFloorPlan());
            }
            canvasRef.current?.renderAll();
          } else {
            rect.set({ visible: false });
          }
        }
      });

      fabric.Image.fromURL(
        svgUrl,
        (img) => {
          img.set({
            left:
              roomGroup.getBoundingRect().left +
              roomGroup.getBoundingRect().width +
              25,
            top: roomGroup.getBoundingRect().top,
            selectable: false,
          });

          canvasRef.current!.add(img);
          console.log("IMAGE", i, roomGroup.getBoundingRect().top);

          img.on("mousedown", (event) => {
            setIsVisible(true);
            setCurrentIndex(startIndex + i);
          });
        },
        {
          crossOrigin: "anonymous",
        },
      );
    }
  };

  //for removing contents of entire drawing board
  const resetDrawBoard = () => {
    const rects = canvasRef.current
      ?.getObjects()
      .filter((obj: any) => obj["type"] === "group" || "image");
    rects?.forEach((rect: any) => {
      canvasRef.current?.remove(rect);
    });
  };

  useEffect(() => {
    const canvas = new fabric.Canvas("canvas", {
      backgroundColor: "#fff",
      selection: false,
    });

    canvasRef.current = canvas;

    const handleResize = () => {
      if (!containerRef.current) return;
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;
      canvas.setDimensions({ width: containerWidth, height: containerHeight });
      setCanWidth(containerWidth);
      setCanHeight(containerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    dispatch(deleteSelectedFloorPlan());
    return () => {
      window.removeEventListener("resize", handleResize);
      canvas.dispose();
    };
  }, []);

  useEffect(() => {
    loadRFP();
  }, [floorPlans, startIndex]);

  //function for pop-up
  useEffect(() => {
    if (currentIndex !== -1) {
      const canvas = new fabric.Canvas("pop-up-canvas", {
        backgroundColor: "#fff",
        selection: false,
      });
      canvas.setWidth(window.innerWidth * 0.6);
      canvas.setHeight(window.innerHeight * 0.75);
      popUpRef.current = canvas;
      let xmin = Infinity;
      let ymin = Infinity;
      let xmax = -Infinity;
      let ymax = -Infinity;

      fabric.Image.fromURL(
        svgUrl2,
        (img) => {
          img.set({
            left: popUpRef.current!.getWidth() - img.width! * 1.2,
            top: 10,
            selectable: false,
          });

          popUpRef.current!.add(img);

          img.on("mousedown", (event) => {
            setIsVisible(false);
          });
        },
        {
          crossOrigin: "anonymous",
        },
      );

      const rooms = floorPlans[currentIndex];

      if (!rooms) {
        return;
      }

      const roomObjs: any[] = [];

      rooms.forEach((room: NewFloorPlanItem) => {
        const walls = room.walls;

        if (!walls) {
          return;
        }

        walls.forEach((wall: Wall) => {
          // Store xmin, xmax, ymin, ymax
          xmin = Math.min(xmin, wall.x1, wall.x2);
          xmax = Math.max(xmax, wall.x1, wall.x2);
          ymin = Math.min(ymin, wall.y1, wall.y2);
          ymax = Math.max(ymax, wall.y1, wall.y2);
        });
      });

      // Second loop to create and set the text objects
      rooms.forEach((room: NewFloorPlanItem, index: number) => {
        const walls = room.walls;

        if (!walls) {
          return;
        }

        const lineGroup = new fabric.Group();
        const roomCenter: { x: number; y: number } = { x: 0, y: 0 };

        let x: number = 0;
        let y: number = 0;

        walls.forEach((wall: Wall) => {
          const line = new fabric.Line(
            [wall.x1 * 25, wall.y1 * 25, wall.x2 * 25, wall.y2 * 25],
            {
              fill: "#fff",
              selectable: false,
              stroke: "black",
              strokeWidth: props.isDimensioned ? (xmax - xmin) / 10 : 1,
            },
          );

          x += wall.x1 + wall.x2;
          y += wall.y1 + wall.y2;
          lineGroup.addWithUpdate(line);
        });

        roomCenter.x = room.label_coord[0];
        roomCenter.y = room.label_coord[1];
        const text = new fabric.Text(`${index + 1}`, {
          fontSize: props.isDimensioned ? (xmax - xmin) * 2.5 : 10, // Dynamic font size based on xmax - xmin
          originX: "center",
          originY: "center",
          fill: "fff",
          selectable: false,
        });

        text.set({
          left:
            roomCenter.x * 25 + (props.isDimensioned ? (xmax - xmin) * 2 : 5),
          top: roomCenter.y * 25,
        });

        lineGroup.addWithUpdate(text);
        roomObjs.push(lineGroup);
      });

      const roomGroup = new fabric.Group([], {
        originX: "center",
        originY: "center",
        selectable: false,
      });

      roomObjs.forEach((obj: any) => {
        roomGroup.addWithUpdate(obj);
      });

      const width80 = canvasRef.current!.getWidth() * 0.8;
      const height80 = canvasRef.current!.getHeight() * 0.8;
      const scaleFactorW = width80 / roomGroup.getScaledWidth();
      const scaleFactorH = height80 / roomGroup.getScaledHeight();

      roomGroup.scaleX = scaleFactorW;
      roomGroup.scaleY = scaleFactorH;

      roomGroup.set({
        left: popUpRef.current!.getWidth() / 2,
        top: popUpRef.current!.getHeight() / 2,
      });

      roomGroup.setCoords();

      popUpRef.current?.add(roomGroup);

      // Calculate and add the dimension text below each roomGroup in popup
      const width = xmax - xmin;
      const height = ymax - ymin;

      const dimensionText = new fabric.Text(`Area: ${height} x ${width}`, {
        fontSize: 16,
        originX: "center",
        originY: "top",
        fill: "black",
        selectable: false,
      });

      dimensionText.set({
        left: roomGroup.getCenterPoint().x,
        top:
          roomGroup.getCenterPoint().y + roomGroup.getScaledHeight() / 2 + 15,
      });

      if (popUpRef.current !== null && props.isDimensioned)
        popUpRef.current.add(dimensionText);
    }
  }, [currentIndex]);

  return (
    <>
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <canvas id="canvas" style={{ width: "100%", height: "100%" }} />
      </div>
      {floorPlans && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "8px",
          }}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={prevRFP}
            >
              <path
                d="M19 12.6323H5"
                stroke="#1C4C82"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 5.63232L5 12.6323L12 19.6323"
                stroke="#1C4C82"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {floorPlans.length > 0 && startIndex + 3 < floorPlans.length
              ? `${startIndex + 1}-${startIndex + 3}`
              : `${startIndex + 1}-${floorPlans.length}`}{" "}
            / {floorPlans.length}{" "}
          </Typography>
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={nextRFP}
            >
              <path
                d="M5 12.6323H19"
                stroke="#1C4C82"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 5.63232L19 12.6323L12 19.6323"
                stroke="#1C4C82"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        </Box>
      )}

      {isVisible && (
        <div
          className="popup"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="popup-inner">
            <canvas id="pop-up-canvas" />
          </div>
        </div>
      )}
    </>
  );
};

export default NewApiSideBarBoard;
