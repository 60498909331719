import React, { useEffect, useRef, useState, useContext } from "react";
import * as d3 from "d3";
import { NameContext } from "./DashBoard";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Sidebar from "./Sidebar";
import {
  updateAddLinks,
  updateAddNodes,
  updateLinks,
  updateNodeLabel,
  resetNodesState,
} from "../../../redux/features/nodes";
import { useSelector } from "react-redux";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import NodeEditor from "./NodeEditor";
import {
  updateNodeEditorClickedNode,
  updateNodeEditorPosition,
  updateShowComponent,
} from "../../../redux/features/nodeEditor/nodeEditor.slice";
import { doLinesIntersect } from "../../../utils/doLinesIntersect";
import { setFromDocuments } from "../../../redux/features/graph";

const CustomDiv = styled.div`
  .svgDualGraph {
    cursor: crosshair;
    display: block;
    background: #eeeeee;
    width: 100vw;
    height: 100vh;
    background-color: #f2f7fc;
  }
  .edge {
    stroke: #1c4c82;
    strokewidth: 3px;
    strokelinecap: round;
    strokelinejoin: round;
    cursor: default;
  }
  .drag-line {
    stroke: #1c4c82;
    strokewidth: 3px;
    strokelinecap: round;
    strokelinejoin: round;
    cursor: default;
  }
  .vertex {
    cursor: pointer;
  }
  .vertex:hover {
    stroke: #333;
    opacity: 0.8;
  }
  .label {
    text-anchor: middle;
    pointer-events: none;
    font-size: 16px;
    fill: #ffffff;
    stroke: #ffffff;
  }
`;

const GRID_SIZE = 20;
const Graph = (props: any) => {
  const RADIUS = 65;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const contentGroupRef = useRef<SVGGElement | null>(null);
  const currentZoom = useRef(d3.zoomIdentity);
  const dispatch = useDispatch<AppDispatch>();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [editorKey, setEditorKey] = useState(0);
  const showComponent = useSelector(
    (store: RootState) => store.nodeEditorState.showComponent,
  );
  const nodes = useSelector((store: RootState) => store?.nodesState?.nodes);
  const links = useSelector((store: RootState) => store.nodesState?.links);
  const fromDocuments = useSelector(
    (store: RootState) => store.graph?.fromDocuments,
  );

  const closeNodeEditor = () => {
    dispatch(updateShowComponent(false));
  };

  const [dragging, setDragging] = useState(false);
  const [dragStartNode, setDragStartNode] = useState<{
    id: number;
    x: number;
    y: number;
  } | null>(null);
  const { setResp } = useContext(NameContext);

  function isNodePossible(
    x: number,
    y: number,
    nodes: { id: number; x: number; y: number; label: string; color: string }[],
  ) {
    if (nodes?.length > 13) {
      return false;
    }
    for (let i = 0; i < nodes?.length; i++) {
      let calc = (nodes[i].x - x) ** 2 + (nodes[i].y - y) ** 2;
      if (calc < (RADIUS * 2) ** 2) return false;
    }
    return true;
  }
  const svg = d3.select(svgRef.current);
  const snapToGrid = (x: number, y: number) => {
    const transformedPoint = currentZoom.current.invert([x, y]);
    return [
      Math.round(transformedPoint[0] / GRID_SIZE) * GRID_SIZE,
      Math.round(transformedPoint[1] / GRID_SIZE) * GRID_SIZE,
    ];
  };

  const zoomBehavior = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.5, 2]) // Example scale extent
    .on("zoom", (event) => {
      console.log("event transform", event.transform);
      currentZoom.current = event.transform;
      console.log(currentZoom.current);
      d3.select(svgRef.current)
        .select(".main-group")
        .attr("transform", event.transform.toString());
      drawGrid(
        d3.select(svgRef.current).select(".grid-group"),
        event.transform,
      ); // Pass the current zoom transform to drawGrid
    });
  useEffect(() => {
    console.log("currentZoom", currentZoom);
  }, [currentZoom.current]);

  useEffect(() => {
    if (!svgRef.current) return;
    d3.select(svgRef.current).selectAll("*").remove();

    const svgElement: SVGSVGElement = svgRef.current;
    const svg = d3.select(svgElement);

    const width = svgElement.clientWidth;
    const height = svgElement.clientHeight;

    const mainGroup = svg.append("g").attr("class", "main-group");

    d3.select(svgRef.current)
      .select(".main-group")
      .attr("transform", currentZoom.current.toString());
    // Grid group
    const gridGroup = mainGroup.append("g").attr("class", "grid-group");
    drawGrid(gridGroup, d3.zoomIdentity);

    // Content group
    const contentGroup = mainGroup.append("g").attr("class", "content-group");
    update(contentGroup);
    // svg.call("zoom",currentZoom.current)
    contentGroupRef.current = contentGroup.node();

    // Define and apply zoom behavior

    svg.call(zoomBehavior as any).on("dblclick.zoom", null);
    svg.call(zoomBehavior).on("dblclick.zoom", null).on("mousedown.zoom", null);
    svg.on("dblclick", (event: MouseEvent) => {
      event.preventDefault();
      const [x, y] = d3.pointer(event);
      const [snappedX, snappedY] = snapToGrid(x, y);
      const [nodeX, nodeY] = d3.pointer(event, svgRef.current);
      const clickedNode = findNode(nodeX, nodeY);
      let check = isNodePossible(snappedX, snappedY, nodes);
      if (check) {
        const id = nodes?.length;
        const label = `${nodes?.length + 1}`;
        const color = "#1C4C82";

        dispatch(
          updateAddNodes({
            id: id,
            x: snappedX,
            y: snappedY,
            label: label,
            color: color,
            width: { max: 99999, min: 3 },
            height: { max: 99999, min: 3 },
            ratio: { max: 99999, min: 3 },
          }),
        );
      }
      if (clickedNode) {
        nodes.forEach((node, index) => {
          if (node.label?.length === 0) {
            dispatch(
              updateNodeLabel({
                id: index,
                label: `${index + 1}`,
              }),
            );
          }
        });
        dispatch(updateNodeEditorClickedNode(clickedNode.id));
        console.log(clickedNode.id);
        dispatch(
          updateNodeEditorPosition({
            top: clickedNode?.y,
            left: clickedNode?.x,
          }),
        );
        dispatch(updateShowComponent(true));
      }
    });
    let dragTimeout: any;

    svg.on("mousedown", (event: MouseEvent) => {
      event.preventDefault();
      const [x, y] = d3.pointer(event, svgRef.current);
      const clickedNode = findNode(x, y);
      console.log("mouseDown called", clickedNode);
      if (clickedNode) {
        // Delay setting the dragging state
        dragTimeout = setTimeout(() => {
          setDragging(true);
          setDragStartNode(clickedNode);

          if (contentGroupRef.current) {
            drawDragLine(
              clickedNode.x,
              clickedNode.y,
              clickedNode.x,
              clickedNode.y,
              d3.select(contentGroupRef.current),
            );
          }
        }, 200); // 200ms delay, adjust as needed
      }
    });

    svg.on("mousemove", (event: MouseEvent) => {
      event.preventDefault();
      if (dragging && dragStartNode) {
        const [x, y] = d3.pointer(event, svg.node());
        const [snappedX, snappedY] = snapToGrid(x, y);
        if (contentGroupRef.current) {
          drawDragLine(
            dragStartNode.x,
            dragStartNode.y,
            snappedX,
            snappedY,
            d3.select(contentGroupRef.current),
          );
        }
      }
    });

    svg.on("click", (event: MouseEvent) => {
      event.preventDefault();
      const [nodeX, nodeY] = d3.pointer(event, svgRef.current);
      const clickedNode = findNode(nodeX, nodeY);

      if (clickedNode === undefined) {
        dispatch(updateShowComponent(false));
      }
    });

    svg.on("mouseup", (event: MouseEvent) => {
      clearTimeout(dragTimeout);
      console.log("reached first line in mouseup", dragging, dragStartNode);
      if (dragging && dragStartNode) {
        const [x, y] = d3.pointer(event, svg.node());
        const endNode = findNode(x, y);
        let intersects = false;
        if (endNode && dragStartNode.id !== endNode.id) {
          for (let link of links) {
            const sourceNode = nodes.find((node) => node.id === link.source);
            const targetNode = nodes.find((node) => node.id === link.target);

            if (sourceNode && targetNode) {
              if (
                doLinesIntersect(
                  { x: dragStartNode.x, y: dragStartNode.y },
                  { x: endNode.x, y: endNode.y },
                  { x: sourceNode.x, y: sourceNode.y },
                  { x: targetNode.x, y: targetNode.y },
                )
              ) {
                intersects = true;
                // alert("intersects");
                break;
              }
            }
          }
        }
        console.log("intersects", intersects);
        if (/* !intersects &&  */ endNode && dragStartNode.id !== endNode.id) {
          console.log("reached here in mouse up!");
          dispatch(
            updateAddLinks({ source: dragStartNode.id, target: endNode.id }),
          );
          update(d3.select(contentGroupRef.current!)); // Immediately update after adding new link
        } /* else if (intersects) {
          console.log("Intersecting links are not allowed.");
          dispatch(
            showToast({
              message: "Intersecting edges are not allowed.",
              type: "error",
            }),
          );
        } */

        removeDragLine(contentGroup);
        setDragging(false);
        setDragStartNode(null);
      }
    });
  }, [nodes, links, dragging, dragStartNode]);

  useEffect(() => {
    console.log("PROPS FROM NODESSS OUTSIDEx", props.nodesFromDoc);
    if (fromDocuments && props.nodesFromDoc && props.edgesFromDoc) {
      dispatch(resetNodesState());
      console.log("PROPS FROM NODESSS");
      const width = visualViewport?.width;
      const height = visualViewport?.height;
      if (width && height) {
        props.nodesFromDoc.forEach((node: any) => {
          const [snappedX, snappedY] = snapToGrid(
            node.x * width,
            node.y * height,
          );
          dispatch(
            updateAddNodes({
              ...node,
              x: snappedX,
              y: snappedY,
            }),
          );
        });
        dispatch(setFromDocuments(false));
      }
      dispatch(updateLinks(props.edgesFromDoc));
    }
  }, []);
  function findNode(x: number, y: number) {
    const [invertedX, invertedY] = currentZoom.current.invert([x, y]);
    return nodes.find((node) => {
      const dx = invertedX - node.x;
      const dy = invertedY - node.y;
      return Math.sqrt(dx * dx + dy * dy) < RADIUS;
    });
  }

  function drawDragLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) {
    removeDragLine(contentGroup);
    contentGroup
      .append("line")
      .attr("class", "drag-line")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("stroke", "#888") // Set the stroke color
      .attr("strokeWidth", 3) // Set the stroke width
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round");
  }

  function removeDragLine(
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) {
    contentGroup.select(".drag-line").remove();
  }

  const drawGrid = (
    gridGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    zoomTransform: d3.ZoomTransform,
  ) => {
    if (!svgRef.current) return;
    const width = +svgRef.current.clientWidth;
    const height = +svgRef.current.clientHeight;
    const GRID_SIZE = 20;
    // Clear the previous grid
    gridGroup.selectAll("*").remove();

    // Calculate the visible area in the original coordinate system
    const visibleWidth = width / zoomTransform.k;
    const visibleHeight = height / zoomTransform.k;

    // Calculate the top-left corner of the visible area
    const startX = -zoomTransform.x / zoomTransform.k;
    const startY = -zoomTransform.y / zoomTransform.k;

    // Draw the grid lines
    for (
      let x = Math.floor(startX / GRID_SIZE) * GRID_SIZE;
      x <= startX + visibleWidth;
      x += GRID_SIZE
    ) {
      for (
        let y = Math.floor(startY / GRID_SIZE) * GRID_SIZE;
        y <= startY + visibleHeight;
        y += GRID_SIZE
      ) {
        gridGroup
          .append("circle")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", 1) // Radius of dots
          .attr("fill", "#ccc");
      }
    }
  };
  const deleteEdge = (sourceId: any, targetId: any) => {
    const updatedLinks = links.filter(
      (link) => link.source !== sourceId || link.target !== targetId,
    );

    dispatch(updateLinks(updatedLinks));
    if (contentGroupRef.current) {
      update(d3.select(contentGroupRef.current));
    }
  };

  function update(
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) {
    const linkSelection = contentGroup
      .selectAll<SVGLineElement, any>(".edge")
      .data(links, (d: any) => `${d.source}-${d.target}`);

    linkSelection
      .enter()
      .append("line")
      .attr("class", "edge")
      .merge(linkSelection as any)
      .attr("x1", (d) => nodes.find((node) => node.id === d.source)?.x || 0)
      .attr("y1", (d) => nodes.find((node) => node.id === d.source)?.y || 0)
      .attr("x2", (d) => nodes.find((node) => node.id === d.target)?.x || 0)
      .attr("y2", (d) => nodes.find((node) => node.id === d.target)?.y || 0)
      .attr("stroke", "#1c4c82")
      .attr("strokeWidth", 3)
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .on("click", (event, d) => {
        event.stopPropagation();
        deleteEdge(d.source, d.target);
      });

    linkSelection.exit().remove();

    const nodeSelection = contentGroup
      .selectAll<SVGCircleElement, any>(".vertex")
      .data(nodes, (d: any) => d.id);

    nodeSelection
      .enter()
      .append("circle")
      .attr("class", "vertex")
      .attr("r", RADIUS)
      .merge(nodeSelection)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .style("fill", (d) => d.color)
      .on("dblclick", (event, d) => {
        dispatch(updateShowComponent(false));
      });

    nodeSelection.exit().remove();

    const labelSelection = contentGroup
      .selectAll<SVGTextElement, any>(".label")
      .data(nodes, (d: any) => d.id);
    labelSelection
      .enter()
      .append("text")
      .attr("class", "label")
      .merge(labelSelection)
      .attr("x", (d) => d.x)
      .attr("y", (d) => d.y + 5)
      .attr("text-anchor", "middle")
      .style("font-size", (d) => {
        // Check if label length exceeds 20 characters
        if (d.label?.length > 16) {
          return "10px"; // Add a line break
        } else {
          return "16px";
        }
      })
      .text((d) => {
        if (d.label.startsWith(`${d.id + 1}`)) {
          return d.label;
        } else {
          return d.id + 1 + "-" + d.label;
        }
      });

    labelSelection.exit().remove();
  }

  useEffect(() => {
    nodes.forEach((node, index) => {
      if (node.label?.length === 0) {
        dispatch(
          updateNodeLabel({
            id: index,
            label: `${index + 1}`,
          }),
        );
      }
    });
  }, [showComponent]);

  return (
    <div style={{ position: "relative" }}>
      {showComponent === true && (
        <div style={{ zIndex: 1001, position: "fixed" }}>
          <NodeEditor
            key={editorKey}
            onClose={() => {
              closeNodeEditor();
              setEditorKey((prevKey) => prevKey + 1); // Increment the key
            }}
          />
        </div>
      )}

      <CustomDiv style={{ zIndex: 100 }}>
        <svg
          ref={svgRef}
          className="svgDualGraph"
          width={visualViewport?.width}
          height={visualViewport?.height}
        />
      </CustomDiv>
      <TopNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <BottomNav isSideBarCollapsed={!sidebarOpen} />
      <div
        style={
          sidebarOpen
            ? {
                position: "fixed",
                zIndex: 1000,
                right: "8px",
                top: "2px",
                transition: "all .25s linear",
              }
            : {
                position: "fixed",
                zIndex: 1000,
                right: "-30%",
                top: "2px",
                transition: "all .25s linear",
              }
        }
      >
        <Sidebar />
      </div>
    </div>
  );
};

export default Graph;
