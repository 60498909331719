import React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import RightArrowIcon from "../../../common-components/icons/RightArrowIcon";
import SelectBoxIcon from "../../../common-components/icons/SelectBoxIcon";
import TagIcon from "../../../common-components/icons/TagIcon";
import TextBoxIcon from "../../../common-components/icons/TextBoxIcon";
import UpLeftCursorIcon from "../../../common-components/icons/UpLeftCursorIcon";
import PenIcon from "../../../common-components/icons/PenIcon";
import ColorPotIcon from "../../../common-components/icons/ColorPotIcon";
import RightCurvedArrowIcon from "../../../common-components/icons/RightCurvedArrowIcon";
import LeftCurvedArrowIcon from "../../../common-components/icons/LeftCurvedArrowIcon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  popRedoStacks,
  popUndoStacks,
  pushRedoStacks,
  pushUndoStacks,
  setCleanUpDone,
  setRooms,
  setWalls,
  setDrawLine,
  clearDrawLine,
  setPlot,
  togglePlot,
} from "../../../redux/features/appState";
import { getFloorplanLimits } from "../utils/helpers";
import { Wall } from "./Floorplan";
import { v4 as uuidv4 } from "uuid";
import DimensionsToggle from "../../../common-components/DimensionsToggle";

export default function BottomNav() {
  const walls = useSelector(
    (state: RootState) => state.outputEditorButtonState.walls,
  );
  const rooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.rooms,
  );
  const showPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.showPlot,
  );
  const plot_boundary = useSelector(
    (state: RootState) => state.outputEditorButtonState.plot_boundary,
  );
  const undoWalls = useSelector(
    (state: RootState) => state.outputEditorButtonState.undoWalls,
  );
  const undoRooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.undoRooms,
  );
  const undoPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.undoPlot,
  );
  const redoWalls = useSelector(
    (state: RootState) => state.outputEditorButtonState.redoWalls,
  );
  const redoRooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.redoRooms,
  );
  const redoPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.redoPlot,
  );

  const dispatch = useDispatch<AppDispatch>();

  const undo = () => {
    if (undoWalls.length > 0 && undoRooms.length > 0)
      dispatch(
        pushRedoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }),
      );
    if (undoWalls?.length > 0) {
      dispatch(setWalls(undoWalls[undoWalls.length - 1]));
    }
    if (undoRooms?.length > 0) {
      dispatch(setRooms(undoRooms[undoRooms.length - 1]));
    }
    if (undoPlot?.length > 0) {
      dispatch(setPlot(undoPlot[undoPlot.length - 1]));
    }
    dispatch(popUndoStacks());
    dispatch(setCleanUpDone(true));
  };

  const redo = () => {
    if (redoWalls.length > 0 && redoRooms.length > 0)
      dispatch(
        pushUndoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }),
      );
    if (redoWalls?.length > 0) {
      dispatch(setWalls(redoWalls[redoWalls.length - 1]));
    }
    if (redoRooms?.length > 0) {
      dispatch(setRooms(redoRooms[redoRooms.length - 1]));
    }
    if (redoPlot?.length > 0) {
      dispatch(setPlot(redoPlot[redoPlot.length - 1]));
    }
    dispatch(popRedoStacks());
    dispatch(setCleanUpDone(true));
  };

  const drawPlot = () => {
    if (plot_boundary && plot_boundary.length > 0) {
      return;
    } else if (rooms) {
      const { minFPX, maxFPX, minFPY, maxFPY } = getFloorplanLimits(rooms);
      let wall1: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: minFPX - 50,
        x2: maxFPX + 50,
        y1: minFPY - 50,
        y2: minFPY - 50,
      };
      let wall2: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: minFPX - 50,
        x2: maxFPX + 50,
        y1: maxFPY + 50,
        y2: maxFPY + 50,
      };
      let wall3: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: minFPX - 50,
        x2: minFPX - 50,
        y1: minFPY - 50,
        y2: maxFPY + 50,
      };
      let wall4: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: maxFPX + 50,
        x2: maxFPX + 50,
        y1: minFPY - 50,
        y2: maxFPY + 50,
      };
      let new_plot_boundary: Wall[] = [wall1, wall2, wall3, wall4];
      dispatch(setPlot(new_plot_boundary));
    }
  };

  // All Tools : arrow, box, pen, tag, pot, text
  const [tool, setTool] = useState("arrow");
  return (
    <>
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          left: "40px",
          bottom: "15px",
          right: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "0px 10px",
              borderRadius: "8px",
              // boxShadow:'2px 2px 2px 1px',
              border: "1px solid #E0E0E0",
            }}
          >
            <Typography
              sx={{
                pl: "10px",
                pr: "10px",
                color: "#333333",
                textAlign: "left",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              28%
            </Typography>
            <Box
              sx={{
                margin: "5px",
              }}
            >
              <RightArrowIcon color="#333333" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "5px",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
            }}
          >
            <Box
              sx={{
                backgroundColor: tool === "arrow" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("arrow");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <UpLeftCursorIcon
                color={tool === "arrow" ? "#ffffff" : "#333333"}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "select" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("select");
                dispatch(clearDrawLine());
                if (!showPlot) drawPlot();
                dispatch(togglePlot());
                dispatch(setCleanUpDone(true));
              }}
            >
              <SelectBoxIcon
                color={tool === "select" ? "#ffffff" : "#333333"}
              />
            </Box>
            <Box
              sx={{
                mr: "15px",
              }}
            >
              <svg
                width="2"
                height="40"
                viewBox="0 0 2 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.839844" width="1" height="40" fill="#E0E0E0" />
              </svg>
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "pen" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("pen");
                dispatch(setDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <PenIcon color={tool === "pen" ? "#ffffff" : "#333333"} />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "tag" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("tag");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <TagIcon color={tool === "tag" ? "#ffffff" : "#333333"} />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "pot" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("pot");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <ColorPotIcon color={tool === "pot" ? "#ffffff" : "#333333"} />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "text" ? "#1C4C82" : "#ffffff",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("text");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <TextBoxIcon color={tool === "text" ? "#ffffff" : "#333333"} />
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
                padding: "5px",
                borderRadius: "8px",
                border: "1px solid #E0E0E0",
              }}
            >
              <Typography
                sx={{
                  pr: "10px",
                  color: "#333333",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {unit === "feet" ? "Feet" : "Meters"}
              </Typography>
              <button
                onClick={() => dispatch(toggleUnit())}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#1C4C82",
                  color: "#ffffff",
                  borderRadius: "4px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Toggle Unit
              </button>
            </Box> */}

            <DimensionsToggle />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "0px 10px",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
            }}
          >
            <Box
              sx={{
                padding: "10px",
              }}
              onClick={undo}
            >
              <LeftCurvedArrowIcon color="#333333" />
            </Box>
            <Box
              sx={{
                padding: "10px",
              }}
              onClick={redo}
            >
              <RightCurvedArrowIcon color="#333333" />
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}
