import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import loginBG from "../../assets/loginBG.png";
import axios from "axios";
import { time } from "console";

// Replace LINK1 and LINK2 with your actual API endpoints
const GENERATE_OTP_URL = `${process.env.REACT_APP_BASEURL}/users/auth/otp/get/`;
const VERIFY_OTP_URL = `${process.env.REACT_APP_BASEURL}/users/auth/otp/verify/`;

interface FormValues {
  email: string;
  otp: number | null;
}
interface OtpState {
  timer: number;
  sentOnce: boolean;
}
function VerifyEmail() {
  const { state } = useLocation();
  const { userData } = state;
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<FormValues>({
    email: userData.email,
    otp: null,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isGenerateDisabled, setGenerateDisabled] = useState<boolean>(true);
  const [otpState, setOtpState] = useState<OtpState>({
    timer: 0,
    sentOnce: false,
  });

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (isGenerateDisabled && otpState.timer > 0) {
      countdown = setInterval(() => {
        setOtpState((prev) => ({ ...prev, timer: prev.timer - 1 }));
      }, 1000);
    } else if (otpState.timer === 0) {
      setGenerateDisabled(false);
      // setOtpState((prev) => ({ ...prev, timer: 30 }));
    }

    return () => clearInterval(countdown);
  }, [isGenerateDisabled, otpState]);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleGenerateOTP = useCallback(
    (email: string) => {
      setErrorMessage(null);
      console.log("generateotp call");
      if (otpState.timer === 0) {
        axios
          .post(
            GENERATE_OTP_URL,
            {
              email,
            },
            {
              headers: { "Content-type": "application/json" },
            },
          )
          .then(() => {
            // Handle success (e.g., show a success message if needed)
            setGenerateDisabled(true);
            setOtpState((prevState) => ({ sentOnce: true, timer: 30 }));
          })
          .catch((error) => {
            setErrorMessage(
              error.response?.data?.message ||
                "Failed to generate OTP. Please try again.",
            );
            setGenerateDisabled(false); // Enable the Generate OTP button again
            setOtpState((prevState) => ({ ...prevState, timer: 30 })); // Reset the timer
          });
      }
    },
    [otpState],
  );

  const handleVerifyOTP = () => {
    setErrorMessage(null);
    axios
      .post(VERIFY_OTP_URL, {
        email: formValues.email,
        otp: formValues.otp,
      })
      .then(() => {
        // Handle successful verification (e.g., navigate to home)
        navigate("/login", { state: { from: "verify" } });
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message ||
            "Invalid OTP or email. Please try again.",
        );
        setFormValues({ ...formValues, otp: 0 });
        setGenerateDisabled(false); // Enable the Generate OTP button again
        setOtpState((prevState) => ({ ...prevState, timer: 30 })); // Reset the timer
      });
  };

  const goToSignup = () => {
    navigate("/signup", { state: { from: "/home" } });
  };
  useEffect(() => {
    if (userData.email && handleGenerateOTP && !otpState.sentOnce) {
      console.log("first");
      handleGenerateOTP(userData.email);
    }
  }, [userData.email, handleGenerateOTP, otpState]);
  useEffect(() => {
    console.log("first", otpState.timer);
  }, [otpState]);
  const isGenerateEnabled = formValues.email !== "" && !isGenerateDisabled;
  const isVerifyEnabled = formValues.email !== "" && formValues.otp !== 0;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        background: `url(${loginBG}) , lightgray 50% / cover no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          padding: "44px 44px 49.5px 44px",
          flexDirection: "column",
          alignItems: "center",
          gap: "33px",
          borderRadius: "8px",
          background: "#FFF",
          boxShadow: "0px 4.4px 11px 0px rgba(138, 142, 148, 0.05)",
          width: "506px", // Ensuring fixed width similar to LoginPage
        }}
      >
        <Box sx={{ height: "91.204px", width: "105.313px" }}>
          {/* SVG Logo */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="110"
            viewBox="0 0 96 110"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.0009 2L93.6018 28.3283V80.9839L48.0009 107.313L2.39819 80.9839V28.3283L48.0009 2Z"
              stroke="#1C4C82"
              strokeWidth="3.59941"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5623 69.9565L65.9584 96.2337"
              stroke="#1C4C82"
              strokeWidth="3.78886"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M74.6055 69.9565L29.2772 96.2336"
              stroke="#1C4C82"
              strokeWidth="3.81813"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M47.9983 2V54.6563M47.9983 54.6563L2.94873 80.8096M47.9983 54.6563L93.6012 80.6264"
              stroke="#1C4C82"
              strokeWidth="3.59941"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.2759 18.6323L21.3536 50.033L21.3345 69.6664"
              stroke="#1C4C82"
              strokeWidth="3.78886"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M48.2749 3.3501L74.6075 69.9564"
              stroke="#1C4C82"
              strokeWidth="3.78886"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5.5px",
          }}
        >
          <Typography
            sx={{
              display: "inline",
              color: "#282828",
              fontFamily: "Poppins",
              fontSize: "26.4px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "39.6px",
            }}
          >
            Verify Email for
            <Typography
              component="span"
              sx={{
                color: "#1C4C82",
                fontFamily: "Poppins",
                fontSize: "26.4px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "39.6px",
              }}
            >
              {" "}
              GPlan
            </Typography>
          </Typography>
          <Typography
            sx={{
              color: "#949CA9",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Verify your Email
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "22px",
            width: "100%",
          }}
        >
          {/* OTP Input */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "5.5px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#282828",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              OTP
            </Typography>
            <TextField
              fullWidth
              required
              size="small"
              InputLabelProps={{
                shrink: false,
                style: {
                  color: "#949CA9",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
              label={formValues.otp === 0 ? "Enter the OTP" : ""}
              name="otp"
              value={formValues.otp}
              onChange={handleTextFieldChange}
              onKeyPress={(e) => {
                if (e.key === "Enter" && isVerifyEnabled) {
                  handleVerifyOTP();
                }
              }}
            />
          </Box>
          {/* Error Message */}
          {errorMessage && (
            <Typography
              sx={{
                color: "red",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "15px",
                alignSelf: "stretch",
              }}
            >
              {errorMessage}
            </Typography>
          )}
          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
            }}
          >
            <Button
              fullWidth
              size="large"
              onClick={() => handleGenerateOTP(userData.email)}
              variant="contained"
              disabled={!isGenerateEnabled}
              sx={{
                backgroundColor: isGenerateEnabled ? "#1C4C82" : "#CECECE",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "17.6px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "26.4px",
                }}
              >
                {isGenerateDisabled
                  ? `Resend In (${otpState.timer}s)`
                  : "Resend "}
              </Typography>
            </Button>
            <Button
              fullWidth
              size="large"
              onClick={handleVerifyOTP}
              variant="contained"
              disabled={!isVerifyEnabled}
              sx={{
                backgroundColor: isVerifyEnabled ? "#1C4C82" : "#CECECE",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "17.6px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "26.4px",
                }}
              >
                Verify OTP
              </Typography>
            </Button>
          </Box>
        </Box>
        {/* Signup Link */}
        <Typography
          sx={{
            color: "#282828",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "center",
          }}
        >
          New to GPlan?{" "}
          <Button onClick={goToSignup}>
            <Typography
              component="span"
              sx={{
                color: "#009EF7",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Get Started
            </Typography>
          </Button>
        </Typography>
      </Box>
    </Box>
  );
}

export default VerifyEmail;
