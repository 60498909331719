import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import createQuickStart from "../../../assets/homepage/createQuickStart.svg";
import existingQuickStart from "../../../assets/homepage/existingQuickStart.svg";
import { useNavigate } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import { Link } from "react-router-dom";
import { YouTube } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { resetNodesState } from "../../../redux/features/nodes";
interface templateProps {
  imageProp: any;
  imageText: string;
  textSize: string;
}

export default function QuickStartSection() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  return (
    <>
      <div className="quickStartSectionBG">
        <Grid
          spacing={5}
          sx={{
            backgroundColor: "#1C4C82",
            pl: "7%",
            pr: "7%",
            pt: "0px",
          }}
        >
          <Typography
            sx={{
              mr: "10px",
              color: "#F2F2F2",
              textAlign: "left",
              fontFamily: "Poppins",
              fontSize: { xs: "13px", sm: "25px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Quick Start
          </Typography>
        </Grid>
        <div className="quickStartSection">
          <div className="scrollQuickStart">
            <div className="quickStartSectionOuter">
              <div
                className="eachQuickStart"
                onClick={() => {
                  dispatch(resetNodesState());
                  navigate("/inputeditor");
                }}
              >
                <Card
                  imageProp={createQuickStart}
                  imageText="Blank Document"
                  textSize="15px"
                />
              </div>
              <div className="userManual">
                <Link
                  to="https://drive.google.com/file/d/17w5pTQTxQfCngV4aQOsjUZ55bbYw8Ul-/view"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card
                    imageProp={<ArticleIcon fontSize="large" />}
                    imageText="User Manual"
                    textSize="15px"
                  />
                </Link>
              </div>
              <div className="userManual">
                <Link
                  to="https://youtu.be/2dhWx3bFnYs?feature=shared"
                  style={{ textDecoration: "none", color: "red" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card
                    imageProp={<YouTube fontSize="large" />}
                    imageText="Video Tutorial"
                    textSize="15px"
                  />
                </Link>
              </div>
              {/* for placeholder */}
              {/* {Array.from({ length: 8 }).map((_, i) => (
                <Card
                  key={i}
                  imageProp={existingQuickStart}
                  imageText="T Shaped"
                  textSize="13px"
                />
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function Card({
  imageProp,
  imageText,
  textSize,
  onClick,
}: templateProps & { onClick?: () => void }) {
  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <Box
          sx={{
            height: "139px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
          }}
        >
          {typeof imageProp === "string" ? (
            <img src={imageProp} height="100%" width="100%" alt="" />
          ) : (
            imageProp
          )}
        </Box>
        <Typography
          sx={{
            color: "#F2F2F2",
            textAlign: "left",
            fontFamily: "Poppins",
            fontSize: textSize,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginTop: "10px",
          }}
        >
          {imageText}
        </Typography>
      </div>
    </>
  );
}
