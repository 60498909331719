import React, { useEffect } from "react";
import Graph from "./components/Graph";
import TopNav from "./components/TopNav";
import BottomNav from "./components/BottomNav";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
const OutputEditorPage = () => {
  // Access the selectedFloorPlan from the Redux store
  const selectedFloorPlan = useSelector(
    (state: RootState) => state.graph.selectedFloorPlan,
  );
  const graphData = useSelector((state: RootState) => state.graph.graphData);
  const floorplans = useSelector((state: RootState) => state.graph.floorPlans);

  // useEffect to print selectedFloorPlan when the component mounts
  useEffect(() => {
    console.log("Floorplans: ", floorplans);
    console.log("Selected Floor Plan:", selectedFloorPlan);
    console.log("Graph Data", graphData);

    // If you want to perform additional actions, such as initializing the editor with the floor plan, you can do so here.
    // For example:
    // initializeEditorWithFloorPlan(selectedFloorPlan);
  }, [selectedFloorPlan]); // Including selectedFloorPlan as a dependency ensures it logs whenever it changes

  return (
    <>
      {/* <TopNav /> */}

      <Graph />
      <BottomNav />
    </>
  );
};

export default OutputEditorPage;
