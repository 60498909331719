interface RoomData {
  corners: number[][];
  width: number;
  height: number;
  area: number;
  label: string; // Added label field
  label_coord: number[];
}

class Room {
  index: number;
  corners: number[][];
  width: number;
  height: number;
  area: number;
  label: string;
  label_coord?: number[];

  constructor(
    index: number,
    corners: number[][],
    width: number,
    height: number,
    area: number,
    label: string,
    label_coord?: number[],
  ) {
    this.index = index;
    this.corners = corners;
    this.width = width;
    this.height = height;
    this.area = area;
    this.label = label;
    this.label_coord = label_coord;
  }
}

export function roomsFromJson(data: { [key: string]: RoomData }): Room[] {
  const rooms: Room[] = [];
  for (const [index, roomData] of Object.entries(data)) {
    const room = new Room(
      parseInt(index, 10),
      roomData.corners,
      roomData.width,
      roomData.height,
      roomData.area,
      roomData.label,
      roomData.label_coord,
    );
    rooms.push(room);
  }
  return rooms;
}
