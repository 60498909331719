import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL!,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url ===
        `${process.env.REACT_APP_BASEURL}/users/auth/token/`
    ) {
      // Redirect to login if refresh attempt fails
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        // Redirect to login if no refresh token
        window.location.href = "/login";
        return Promise.reject(error);
      }

      return axios
        .post(`${process.env.REACT_APP_BASEURL}/users/auth/token/refresh/`, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("access_token", res.data.access);
            originalRequest.headers["Authorization"] =
              "Bearer " + res.data.access;
            return axios(originalRequest);
          }
        })
        .catch((refreshError) => {
          // Redirect to login if refresh fails
          window.location.href = "/login";
          return Promise.reject(refreshError);
        });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
