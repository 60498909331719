import { NewFloorPlanItem } from "../redux/features/graph";
import { ApiRoom, ConvertedRoom } from "../types";

export function formatLabel(input: string): string {
  const regex = /.\([^)]*\)/;
  const match = input.match(regex);
  if (match) {
    const contentInsideParentheses = match[0].substring(2, match[0].length - 1);
    return contentInsideParentheses;
  }
  return input;
}

export const convertToDxfFormat = (
  rooms: NewFloorPlanItem[],
): Record<number, ConvertedRoom> => {
  const convertedRooms: Record<number, ConvertedRoom> = {};

  // Find the maximum Y coordinate to use for flipping
  let maxY = -Infinity;
  rooms.forEach((room) => {
    room.walls.forEach((wall) => {
      maxY = Math.max(maxY, wall.y1, wall.y2);
    });
  });

  rooms.forEach((room, index) => {
    const corners: number[][] = room.walls.flatMap((wall) => [
      [wall.x1 * 25, (maxY - wall.y1) * 25],
      [wall.x2 * 25, (maxY - wall.y2) * 25],
    ]);

    convertedRooms[index] = {
      corners: corners,
      width: room.width * 25,
      height: room.height * 25,
      area: room.area,
      label: room.name,
      label_coord: room.label_coord
        ? [room.label_coord[0] * 25, (maxY - room.label_coord[1]) * 25]
        : [0],
    };
  });

  return convertedRooms;
};
export const scaleCoordinate = (coord: number) => {
  const scaleFactor = 2; // Adjust this value to make the floorplan larger or smaller
  return coord * scaleFactor;
};
