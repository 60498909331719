import React, { useCallback, useEffect } from "react";
import help from "./../../../assets/inputGraphEditor/help.svg";
import { Box, Typography, Tooltip, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetNodesState } from "../../../redux/features/nodes";
import { resetRoomState } from "../../../redux/features/room/room.slice";
import DimensionsToggle from "../../../common-components/DimensionsToggle";
import RefreshIcon from "@mui/icons-material/Refresh";

const tooltipContent = (
  <Box
    sx={{
      padding: "8px",
      borderRadius: "4px",
      backgroundColor: "#FFFFFF",
      boxShadow: 3,
      minWidth: "250px",
    }}
  >
    <Typography
      sx={{
        color: "#111111",
        textAlign: "left",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        marginBottom: ".75rem",
        whiteSpace: "nowrap",
      }}
    >
      Index
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        whiteSpace: "nowrap",
        marginBottom: "0.5rem",
      }}
    >
      <Box
        sx={{ backgroundColor: "#ED4337", height: "4px", width: "40px" }}
      ></Box>
      <Typography
        sx={{
          color: "#111111",
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          whiteSpace: "nowrap",
        }}
      >
        No common walls or Door
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        whiteSpace: "nowrap",
        marginBottom: "0.5rem",
      }}
    >
      <Box
        sx={{ backgroundColor: "#1C4C82", height: "4px", width: "40px" }}
      ></Box>
      <Typography
        sx={{
          color: "#111111",
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          whiteSpace: "nowrap",
        }}
      >
        Connect Rooms with Door
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
      <Box
        sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
      ></Box>
      <Box
        sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
      ></Box>
      <Box
        sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
      ></Box>
      <Box
        sx={{ backgroundColor: "#1C4C82", height: "4px", minWidth: "7px" }}
      ></Box>
      <Typography
        sx={{
          color: "#111111",
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        Connect Rooms with Common Wall
      </Typography>
    </Box>
  </Box>
);
interface BottomNavProps {
  isSideBarCollapsed?: boolean;
}
export default function BottomNav({
  isSideBarCollapsed = true,
}: BottomNavProps) {
  const dispatch = useDispatch();

  const handleReload = useCallback(() => {
    console.log("refresh triggered");
    dispatch(resetNodesState());
    dispatch(resetRoomState());
  }, [dispatch]);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1000,
        left: "15px",
        bottom: "15px",
        width: "70%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tooltip title={tooltipContent} arrow placement="top">
          <div style={{ zIndex: 100, cursor: "pointer", position: "relative" }}>
            <img src={help} height="40px" width="40px" alt="Help icon" />
            <Typography
              sx={{
                pr: "15px",
                color: "#1C4C82",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Help
            </Typography>
          </div>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingRight: "2rem",
            position: "fixed",
            right: isSideBarCollapsed ? "-10px" : "calc(30% + 10px)", // Adjust this value based on your layout
            bottom: "15px",
            transform: isSideBarCollapsed ? "translateX(0)" : "translateX(0)",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <DimensionsToggle />
          <Button
            variant="outlined"
            sx={{
              borderColor: "#ED4337",
              color: "#ED4337",
              minWidth: isSideBarCollapsed ? "48px" : "200px", // Initial width when expanded
              width: isSideBarCollapsed ? "48px" : "auto",
              height: "48px",
              padding: isSideBarCollapsed ? 0 : "12px 20px",
              borderRadius: isSideBarCollapsed ? "50%" : "8px",
              backgroundColor: "#FDEDEC",
              transition: "all 0.25s ease-in-out",
              position: "relative",
              overflow: "hidden",
              whiteSpace: "nowrap",
              "& .MuiSvgIcon-root": {
                fontSize: "24px",
                transition: "all 0.25s ease-in-out",
                opacity: isSideBarCollapsed ? 1 : 0,
                position: "absolute",
                left: "50%",
                transform: isSideBarCollapsed
                  ? "translateX(-50%)"
                  : "translateX(-50%) scale(0)",
              },
              "&:hover": {
                borderColor: "#ED4337",
                backgroundColor: "#F9CACA",
                transform: isSideBarCollapsed ? "scale(1.05)" : "scale(1.02)",
                "& .MuiSvgIcon-root": {
                  transform: isSideBarCollapsed
                    ? "translateX(-50%) rotate(180deg)"
                    : "translateX(-50%) scale(0)",
                },
              },
              "&:active": {
                transform: "scale(0.95)",
              },
              // Text styles
              "& .button-text": {
                transition: "all 0.25s ease-in-out",
                opacity: isSideBarCollapsed ? 0 : 1,
                transform: isSideBarCollapsed
                  ? "translateX(20px)"
                  : "translateX(0)",
              },
            }}
            onClick={handleReload}
          >
            <RefreshIcon />
            <span className="button-text">Reset & Clear All Nodes</span>
          </Button>
        </Box>
      </Box>
    </div>
  );
}
