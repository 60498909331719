import ReactGA from "react-ga4";

export const initGA = (): void => {
  ReactGA.initialize("G-60ZNYE1C9Z"); // Replace with your Measurement ID
};

export const logPageView = (): void => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

interface EventProps {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
  customData?: { [key: string]: any };
}

export const logEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
  customData,
}: EventProps): void => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
    ...customData,
  });
};
