import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

function TextBoxIcon(props: { color: String }) {
  return (
    <SvgIcon sx={{ color: `${props.color}` }} fontSize="large">
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 25"
        stroke="currentColor"
      >
        <path
          id="Imported Path"
          fill="none"
          strokeWidth="1"
          d="M -917.00,-335.00
           C -917.00,-335.00 3930.00,-335.00 3930.00,-335.00
             3930.00,-335.00 3930.00,-337.00 3930.00,-337.00
             3930.00,-337.00 -917.00,-337.00 -917.00,-337.00
             -917.00,-337.00 -917.00,-335.00 -917.00,-335.00 Z
           M 3931.00,-334.00
           C 3931.00,-334.00 3931.00,2772.00 3931.00,2772.00
             3931.00,2772.00 3933.00,2772.00 3933.00,2772.00
             3933.00,2772.00 3933.00,-334.00 3933.00,-334.00
             3933.00,-334.00 3931.00,-334.00 3931.00,-334.00 Z
           M 3930.00,2773.00
           C 3930.00,2773.00 -917.00,2773.00 -917.00,2773.00
             -917.00,2773.00 -917.00,2775.00 -917.00,2775.00
             -917.00,2775.00 3930.00,2775.00 3930.00,2775.00
             3930.00,2775.00 3930.00,2773.00 3930.00,2773.00 Z
           M -918.00,2772.00
           C -918.00,2772.00 -918.00,-334.00 -918.00,-334.00
             -918.00,-334.00 -920.00,-334.00 -920.00,-334.00
             -920.00,-334.00 -920.00,2772.00 -920.00,2772.00
             -920.00,2772.00 -918.00,2772.00 -918.00,2772.00 Z
           M -917.00,2773.00
           C -917.55,2773.00 -918.00,2772.55 -918.00,2772.00
             -918.00,2772.00 -920.00,2772.00 -920.00,2772.00
             -920.00,2773.66 -918.66,2775.00 -917.00,2775.00
             -917.00,2775.00 -917.00,2773.00 -917.00,2773.00 Z
           M 3931.00,2772.00
           C 3931.00,2772.55 3930.55,2773.00 3930.00,2773.00
             3930.00,2773.00 3930.00,2775.00 3930.00,2775.00
             3931.66,2775.00 3933.00,2773.66 3933.00,2772.00
             3933.00,2772.00 3931.00,2772.00 3931.00,2772.00 Z
           M 3930.00,-335.00
           C 3930.55,-335.00 3931.00,-334.55 3931.00,-334.00
             3931.00,-334.00 3933.00,-334.00 3933.00,-334.00
             3933.00,-335.66 3931.66,-337.00 3930.00,-337.00
             3930.00,-337.00 3930.00,-335.00 3930.00,-335.00 Z
           M -917.00,-337.00
           C -918.66,-337.00 -920.00,-335.66 -920.00,-334.00
             -920.00,-334.00 -918.00,-334.00 -918.00,-334.00
             -918.00,-334.55 -917.55,-335.00 -917.00,-335.00
             -917.00,-335.00 -917.00,-337.00 -917.00,-337.00 Z
           M 32.60,-15.21
           C 36.79,-15.21 40.19,-11.82 40.19,-7.63
             40.19,-7.63 40.19,32.77 40.19,32.77
             40.19,36.96 36.79,40.36 32.60,40.36
             32.60,40.36 -316.00,40.36 -316.00,40.36
             -316.00,40.36 -316.00,40.36 -316.00,40.36
             -320.19,40.36 -323.58,36.96 -323.58,32.77
             -323.58,32.77 -323.58,-7.63 -323.58,-7.63
             -323.58,-11.82 -320.19,-15.21 -316.00,-15.21
             -316.00,-15.21 -316.00,-15.21 -316.00,-15.21
             -316.00,-15.21 32.60,-15.21 32.60,-15.21 Z
           M 10.94,18.20
           C 10.94,18.20 10.94,7.06 10.94,7.06
             10.94,7.06 9.98,7.06 9.98,7.06
             9.44,7.06 8.99,7.08 8.64,7.12
             8.30,7.16 8.04,7.25 7.86,7.38
             7.70,7.50 7.61,7.68 7.58,7.92
             7.58,7.92 7.42,9.96 7.42,9.96
             7.42,9.96 6.08,9.96 6.08,9.96
             6.08,9.96 6.08,5.80 6.08,5.80
             6.08,5.80 18.34,5.80 18.34,5.80
             18.34,5.80 18.34,9.96 18.34,9.96
             18.34,9.96 17.00,9.96 17.00,9.96
             17.00,9.96 16.82,7.92 16.82,7.92
             16.81,7.68 16.72,7.50 16.54,7.38
             16.38,7.25 16.13,7.16 15.78,7.12
             15.44,7.08 14.99,7.06 14.44,7.06
             14.44,7.06 13.48,7.06 13.48,7.06
             13.48,7.06 13.48,18.20 13.48,18.20
             13.48,18.49 13.62,18.69 13.90,18.78
             14.20,18.87 14.56,18.92 15.00,18.92
             15.00,18.92 15.32,18.92 15.32,18.92
             15.32,18.92 15.32,20.00 15.32,20.00
             15.32,20.00 9.10,20.00 9.10,20.00
             9.10,20.00 9.10,18.92 9.10,18.92
             9.10,18.92 9.42,18.92 9.42,18.92
             9.86,18.92 10.22,18.87 10.50,18.78
             10.80,18.69 10.94,18.49 10.94,18.20 Z
           M 20.01,2.00
           C 20.01,2.00 3.68,2.00 3.68,2.00
             2.39,2.00 1.34,3.04 1.34,4.33
             1.34,4.33 1.34,20.67 1.34,20.67
             1.34,21.96 2.39,23.00 3.68,23.00
             3.68,23.00 20.01,23.00 20.01,23.00
             21.30,23.00 22.34,21.96 22.34,20.67
             22.34,20.67 22.34,4.33 22.34,4.33
             22.34,3.04 21.30,2.00 20.01,2.00 Z
           M 32.60,-15.63
           C 37.02,-15.63 40.60,-12.05 40.60,-7.63
             40.60,-7.63 40.60,32.77 40.60,32.77
             40.60,37.19 37.02,40.77 32.60,40.77
             32.60,40.77 -316.00,40.77 -316.00,40.77
             -320.42,40.77 -324.00,37.19 -324.00,32.77
             -324.00,32.77 -324.00,-7.63 -324.00,-7.63
             -324.00,-12.05 -320.42,-15.63 -316.00,-15.63
             -316.00,-15.63 32.60,-15.63 32.60,-15.63 Z
           M -919.00,-334.00
           C -919.00,-335.11 -918.11,-336.00 -917.00,-336.00
             -917.00,-336.00 3930.00,-336.00 3930.00,-336.00
             3931.10,-336.00 3932.00,-335.11 3932.00,-334.00
             3932.00,-334.00 3932.00,2772.00 3932.00,2772.00
             3932.00,2773.10 3931.10,2774.00 3930.00,2774.00
             3930.00,2774.00 -917.00,2774.00 -917.00,2774.00
             -918.11,2774.00 -919.00,2773.10 -919.00,2772.00
             -919.00,2772.00 -919.00,-334.00 -919.00,-334.00 Z
           M 24.00,0.00
           C 24.00,0.00 24.00,25.00 24.00,25.00
             24.00,25.00 0.00,25.00 0.00,25.00
             0.00,25.00 0.00,0.00 0.00,0.00
             0.00,0.00 24.00,0.00 24.00,0.00 Z"
        />
      </svg>
    </SvgIcon>
  );
}

export default TextBoxIcon;
