import { useEffect } from "react";
import Routes from "./app/Routes";
import { initGA } from "./utils/analytics";

const App = () => {
  useEffect(() => {
    initGA();
  }, []);
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
